import { createApp } from "vue";
import "./style.css";
import App from "./App.vue";
import router from "./router";
import DataVVue3 from "@kjgl77/datav-vue3";
import { createPinia } from "pinia";
import "element-plus/dist/index.css";
import 'element-plus/theme-chalk/dark/css-vars.css'
import '@/style/style.css'

import 'core-js/stable';
import 'regenerator-runtime/runtime';

const app = createApp(App);

app.use(DataVVue3);
app.use(router);
app.use(createPinia());
app.mount("#app");
